.App {
  text-align: center;
  font-family: Urbanist;
  background-color: white;
  --loading-color: #000000;
}

.logo {
  display: inline-block;
  height: 5vh;
  margin-right: 13px;
}

.App-header {
  background-color: white;
  min-height: 7vh;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: calc(10px + 1vmin);
  font-family: Urbanist;
  font-weight: 600;
  color: black;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
}

.App-body {
  padding: 20px;
  text-align: left;
  max-height: 80vh;
  overflow-y: auto;
}

.article {
  margin: 2em 0;
}

.article h2 {
  font-size: 2em;
  color: #333333;
  margin-bottom: 0em;
}

.article p {
  font-size: 1.2em;
  color: #606060;
  line-height: 1.5;
}

.title-date {
  display: flex;
  align-items: baseline;
}

.title-date h1 {
  margin-right: 10px;
}

.title-date .date {
  font-size: 0.85em;
  color: #aaaaaa;
}
/* 
.title-date h2::after {
  content: "•";
  margin: 0 10px;
} */

.empty-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  color: black;
}

.empty-content h3 {
  font-size: 3em;
  font-weight: 600;
}

.empty-content p {
  font-size: 1.5em;
  color: #333333;
}

.logo-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.logo-text h1 {
  margin: 0;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Urbanist';
  src: url('/public/assets/fonts/Urbanist-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

article {
  margin-bottom: 2em;
}

article h2 {
  font-size: 1.5em;
  color: #282c34;
}

article p {
  font-size: 1em;
  color: #606060;
}

.splash-animation {
  position: absolute;
  right: 0;
  top: 0;
  height: 0;
  width: 10px;
  background-color: black;
  animation: splash 3s forwards cubic-bezier(0.22, 1, 0.36, 1);
  animation-delay: 1s;
}


.fade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: fadeOverlay 3s forwards;
  animation-delay: 1s;
  z-index: 1;
}

@keyframes splash {
  0% {
    height: 0;
  }
  25% {
    height: 25vh;
  }
  50% {
    height: 75vh;
  }
  75% {
    height: 90vh;
  }
  100% {
    height: 0;
    top: 100%;
  }
}

@keyframes fadeOverlay {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-bar {
  position: absolute;
  top: 7vh;  
  left: 0;
  height: 4px;
  width: 0;
  background-color: var(--loading-color);
  animation: loadProgress 4s forwards cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 1;
}

@keyframes loadProgress {
  0% {
    width: 0;
    transform: translateX(0);
  }
  50% {
    width: 100%;
    transform: translateX(0);
  }
  100% {
    width: 100%;
    transform: translateX(100%);
  }
}
